import CustomText from '@components/shared/CustomText';
import Empty from '@assets/icons/empty.svg';
import AppShowcase from '@components/AppShowcase';
import styles from './FormNotFound.module.scss';

const FormNotFound = () => {
  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        <div className={styles.errorBox}>
          <Empty />
        </div>
        <CustomText bold customStyle={styles.title}>
          Form not found
        </CustomText>
        <CustomText customStyle={styles.info}>
          The link has expired, ask Doc Manager to send you a new link.
        </CustomText>
      </div>
      <AppShowcase />
    </div>
  );
};

export default FormNotFound;
