import FormDetails from '@features/FormDetails';

const FormDetailsPage = () => {
  return (
    <div>
      <FormDetails />
    </div>
  );
};

export default FormDetailsPage;
