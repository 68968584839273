import AppShowcaseImage from '@assets/images/app-showcase.png';
import CustomText from '@components/shared/CustomText';
import GetStartedButton from '@components/shared/GetStartedButton';
import Image from 'next/image';
import styles from './AppShowcase.module.scss';

const AppShowcase = () => {
  return (
    <div className={styles.appShowcase}>
      <div className={styles.content}>
        <CustomText bold customStyle={styles.mainText}>
          Make this process easier.{' '}
          <a
            href=''
            target='_blank'
            rel='noopener noreferrer'
            className={styles.underline}
          >
            Download the EverySign app!
          </a>
        </CustomText>

        <CustomText customStyle={styles.secondaryText}>
          {`You'll be able to complete any kind of digital document in
                seconds.`}
        </CustomText>

        <GetStartedButton />
      </div>

      <div className={styles.imageContainer}>
        <div className={styles.image}>
          <Image src={AppShowcaseImage} fill alt='app showcase' />
        </div>
      </div>
    </div>
  );
};

export default AppShowcase;
