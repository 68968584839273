import Link from 'next/link';
import PropTypes from 'prop-types';
import CustomButton from '@components/shared/CustomButton';
import CustomText from '@components/shared/CustomText';
import { useMediaQuery } from 'react-responsive';
import AppShowcase from '@components/AppShowcase';
import styles from './RenderForm.module.scss';

const RenderForm = ({ form }) => {
  const isMobile = useMediaQuery({ query: `(max-width: 768px)` });
  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        <CustomText bold customStyle={styles.title}>
          {form?.form?.form_name}
        </CustomText>
        <div className={styles.box}>
          <CustomText customStyle={styles.label} bold>
            Language
          </CustomText>
          <CustomText customStyle={styles.info} bold>
            {form?.form?.language}
          </CustomText>
        </div>

        <div className={styles.buttonContainer}>
          <Link href='/new-signature' className={styles.button}>
            <CustomButton text='Start Doc' />
          </Link>
          <a
            href={form?.form?.url}
            target='_blank'
            rel='noreferrer noopener'
            className={styles.button}
          >
            <CustomButton text={isMobile ? 'Review' : 'Review Doc'} secondary />
          </a>
        </div>
      </div>
      <AppShowcase />
    </div>
  );
};

RenderForm.propTypes = {
  form: PropTypes.shape({
    guestId: PropTypes.string.isRequired,
    jwt: PropTypes.string.isRequired,
    project_id: PropTypes.string.isRequired,
    form: PropTypes.shape({
      company: PropTypes.string,
      created_by: PropTypes.string,
      form_name: PropTypes.string,
      form_owner: PropTypes.string,
      form_type: PropTypes.string,
      hasGuardian: PropTypes.string,
      is_delete: PropTypes.string,
      is_template: PropTypes.string,
      language: PropTypes.string,
      maxHeight: PropTypes.string,
      maxWidth: PropTypes.string,
      subjects: PropTypes.array,
      url: PropTypes.string,
      user_id: PropTypes.string,
      _id: PropTypes.string,
    }).isRequired,
  }),
};
export default RenderForm;
