import FormNotFound from '@components/FormNotFound';
import Layout from '@components/Layout';
import RenderForm from '@components/RenderForm';
import ClipLoader from 'react-spinners/ClipLoader';

import useGetForm from '@hooks/useGetForm';

import { useFormContext } from 'context/FormContext';
import { getUserData, setUserData } from 'localStorage/userData';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';

import styles from './FormDetails.module.scss';

const FormDetails = () => {
  const [existingForm, setExistingForm] = useState();
  const router = useRouter();

  const { guestId } = router.query;
  const { setForm, setGuestId } = useFormContext();
  const {
    data: form,
    isFetching,
    isFetched,
    isError,
    refetch,
  } = useGetForm(guestId, {
    onSuccess: res => {
      setForm(res);
      setGuestId(guestId);
      setUserData({ ...res, guestId });
    },
    enabled: false,
  });

  useEffect(() => {
    if (guestId) {
      const res = getUserData();
      if (res && res.guestId === guestId) {
        setExistingForm(res);
        setGuestId(guestId);
        setForm(res);
      } else refetch();
    }
  }, [setGuestId, setForm, refetch, guestId]);

  if (existingForm)
    return (
      <Layout>
        <RenderForm form={existingForm} />
      </Layout>
    );

  return (
    <Layout>
      {!isFetched && (
        <div className={styles.spinner}>
          <ClipLoader loading={!isFetched} size={150} color='#ff5b01' />
        </div>
      )}
      {isError && <FormNotFound />}
      {form && !isFetching && !isError && (
        <RenderForm form={{ ...form, guestId }} />
      )}
    </Layout>
  );
};

export default FormDetails;
