import { acceptInvite } from '@api/lambda/guest';
import { useQuery } from '@tanstack/react-query';

const useGetForm = (data, options) =>
  useQuery(['form', JSON.stringify(data)], () => acceptInvite(data), {
    enabled: !!data,
    ...options,
  });

export default useGetForm;
